import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios'

const Contact = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const sendMessage = (e) => {
    e.preventDefault()
    const senderEmail = email.trim()
    const senderMessage = message.trim()
    const messageLabel = document.getElementById('message_label')
    const emailLabel = document.getElementById('email_label')

    if (senderMessage.length < 11) {
      messageLabel.style.color = 'red'
      messageLabel.innerText = 'Please write a message longer than 10 characters.'
      return
    } else {
      messageLabel.style.color = 'hsla(0, 0%, 0%, 0.8)'
      messageLabel.innerText = 'Your message'
    }

    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if (!pattern.test(String(senderEmail).toLowerCase())) {
      emailLabel.style.color = 'red'
      emailLabel.innerText = 'Please write a valid email address'
      return
    } else {
      emailLabel.style.color = 'hsla(0, 0%, 0%, 0.8)'
      emailLabel.innerText = 'Your email'
    }

    const newMessage = {
      senderMessage, senderEmail 
    }

    const sendButton = document.getElementById('send_button')
    sendButton.disabled = true
    sendButton.style.backgroundColor = 'orange'
    sendButton.innerText = 'Sending...'
    const resetForm = () => {
      document.getElementById('message_input').value = ''
      setMessage('')
      document.getElementById('message_email').value = ''
      setEmail('')
      sendButton.style.backgroundColor = '#337ab7'
      sendButton.disabled = false
      sendButton.innerText = 'Send Message' 
    }

    // to use on local server change to: http://localhost:3005/contactform
    axios.post('/contactform', newMessage)
    .then( res => {
      if (res.data.success) {
        sendButton.style.backgroundColor = 'green'
        sendButton.innerText = 'Message sent!'
        setTimeout(() => {
          resetForm()
        }, 3000)
      } else if (res.status === '500') {
        alert('There was an internal server error in handling your message.\nThe message was not sent.\nPlease try again later')
        //resetForm()
      }
    })
    .catch( (error) => {
      console.log(error)
      alert('There was an error.\nThe message was not sent.\nPlease try again later' + error)
      //resetForm()
    })    
  }
  
  return (
    <Layout pageTitle="Contact">
      <SEO title="Contact" />
      <h1 style={{ textAlign: 'center' }}>Contact</h1>
      
      <form onSubmit={ sendMessage }>
        <label id="message_label" htmlFor="message_input">Your Message</label>
        <textarea onChange={e =>  setMessage(e.target.value)} name="message" id="message_input" type="text" placeholder="" value={ message.value } required />

        <label id="email_label" htmlFor="message_email">Your Email</label>
        <input onChange={e =>  setEmail(e.target.value)} name="email" id="message_email" type="email" placeholder="" value={ email.value } required />
       
        <button type="submit" id="send_button" style={{ fontWeight: '600' }}>Send</button>
      </form>

    </Layout>
  )
}  

export default Contact
